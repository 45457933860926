body {
	color: #fff;
	background: #301435eb;
	font-family: 'Roboto', sans-serif;
	font-size: 1.2rem;
}

.btn {
	background-color: #e0423f !important;
	border-color: #ad0b08 !important;
	white-space: normal !important;
}

.site-main-heading {
	font-size: 7rem;
}
.fa {
	color: #e0423f !important;
}

.a {
	color: #e0423f !important;
}

.form-control {
	height: 40px;
	box-shadow: none;
	color: #969fa4;
}

.form-control:focus {
	border-color: #e0423f !important;
}

.form-control,
.btn {
	border-radius: 3px;
}

.signup-form {
	margin: 0 auto;
	padding: 30px 15px 15px 15px;
}

.signup-form h2 {
	color: #e0423f !important;
	font-size: 2.2rem;
	position: relative;
	text-align: center;
}

.signup-form .hint-text {
	color: #fff;
	margin-bottom: 30px;
	text-align: center;
}

.signup-form form {
	color: #fff;
	border-radius: 3px;
	margin-bottom: 15px;
	background: #301435;
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
	padding: 30px;
}

.signup-form .form-group {
	margin-bottom: 20px;
}

.signup-form input[type="checkbox"] {
	margin-top: 3px;
}

.signup-form .btn {
	font-size: 16px;
	font-weight: bold;
	min-width: 140px;
	outline: none !important;
	overflow: hidden;
    white-space: normal;
    display: inline-block;
    text-overflow: ellipsis;
}

.signup-form .row div:first-child {
	padding-right: 10px;
}

.signup-form .row div:last-child {
	padding-left: 10px;
}

.signup-form a {
	color: #e0423f !important;
	text-decoration: underline;
}

.signup-form a:hover {
	text-decoration: none;
}

.signup-form form a {
	color: #e0423f !important;
	text-decoration: none;
}

.signup-form form a:hover {
	text-decoration: underline;
}

.text-info {
	color: #fff !important;
}

a {
	color: #e0423f !important;
}

.equal {
	display: flex;
	display: -webkit-flex;
	flex-wrap: wrap;
}

@media (min-width: 768px) {
	.row.equal {
		display: flex;
		flex-wrap: wrap;
	}
}

.headingNeonText {
	text-transform: uppercase;
	background-image: linear-gradient(
	  -225deg,
	  #231557 0%,
	  #44107a 29%,
	  #ff1361 67%,
	  #fff800 100%
	);
	background-size: auto auto;
	background-clip: border-box;
	background-size: 200% auto;
	color: #fff;
	background-clip: text;
	text-fill-color: transparent;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	animation: textclip 2s linear infinite;
	display: inline-block;
	font-size: 7rem;
}

@keyframes textclip {
	to {
	  background-position: 200% center;
	}
}
  
.neonText {
	animation: flicker 1.5s infinite alternate;
	color: #fff;
}

@keyframes flicker {

	0%, 18%, 22%, 25%, 53%, 57%, 100% {

		text-shadow:
			0 0 4px #fff,
			0 0 11px #fff,
			0 0 19px #fff,
			0 0 40px #0fa,
			0 0 80px #0fa,
			0 0 90px #0fa,
			0 0 100px #0fa,
			0 0 150px #0fa;

	}

	20%, 24%, 55% {
		text-shadow: none;
	}
}

.wrapper {
	text-align: center;
}
.wrapper .btn-website-link {
	color: #fff;
	font-size: 2rem;
	text-transform: uppercase;
	font-weight: 700;
	font-family: "Josefin Sans", sans-serif;
	/* background: linear-gradient(to right,#095fab 10%, #25abe8 50%, #57d75b 60%); */
	background: linear-gradient(to right,#e46c13 10%, #25abe8 50%, #57d75b 60%);
	background-size: auto auto;
	background-clip: border-box;
	background-size: 200% auto;
	color: #fff;
	background-clip: text;
	text-fill-color: transparent;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	animation: textclip 1.5s linear infinite;
	display: inline-block;
}

@keyframes textclip {
to {
	background-position: 200% center;
}
}
